import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// @ts-ignore
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/RegisterView.vue')
  },
  {
    path: '/blogMain',
    name: 'blogMain',
    redirect:'/blogInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/blog/IndexBlogMain.vue'),
    children:[
      {
        path: '/blogInfo',
        name:'blogInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/blog/BlogInfo.vue'),
      }
    ]
  },
  {
    path: '/userMain',
    name: 'userMain',
    redirect:'/userInfo',
    component: () => import( '../views/user/IndexUserMain.vue'),
    children:[
      {
        path: '/userInfo',
        name:'userInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserInfo.vue'),
      },
      {
        path: '/userSetting',
        name:'userSetting',
        redirect:"/userSetting/userInfo",
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserSettingMain.vue'),
        children:[
          {
            path: '/userSetting/userInfo',
            name:'userSettingUserInfo',
            component: () => import(/* webpackChunkName: "about" */ '../views/user/UserSetting.vue'),
          },
          {
            path: '/userSetting/userAccount',
            name:'userSettingUserAccount',
            component: () => import(/* webpackChunkName: "about" */ '../views/user/UserAccountView.vue'),
          },
          {
            path: '/userSetting/userFavorite',
            name:'userSettingUserFavorite',
            component: () => import(/* webpackChunkName: "about" */ '../views/user/UserFavoriteView.vue'),
          },
          {
            path: '/userSetting/userHistory',
            name:'userSettingUserHistory',
            component: () => import(/* webpackChunkName: "about" */ '../views/user/UserHistoryView.vue'),
          },
        ]
      },
    ]
  },
  {
    path: '/',
    name: 'index',
    redirect:'/indexCSSView',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue'),
    children:[
      {
        path: '/indexCSSView',
        name:'indexCSSView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexCSSView.vue'),
      },
      {
        path: '/indexVueView',
        name:'indexVueView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexVueView.vue'),
      },
      {
        path: '/indexPHPView',
        name:'indexPHPView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexPHPView.vue'),
      },
      {
        path: '/indexPythonView',
        name:'indexPythonView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexPythonView.vue'),
      },
      {
        path: '/indexJAVAView',
        name:'indexJAVAView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexJAVAView.vue'),
      },
      {
        path: '/indexGolangView',
        name:'indexGolangView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexGolangView.vue'),
      },
      {
        path: '/indexSQLView',
        name:'indexSQLView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexSQLView.vue'),
      },
      {
        path: '/indexElasticSearchView',
        name:'indexElasticSearchView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexElasticSearchView.vue'),
      },
      {
        path: '/indexInterviewView',
        name:'indexInterviewView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexInterviewView.vue'),
      },
      {
        path: '/indexResumeView',
        name:'indexResumeView',
        component: () => import(/* webpackChunkName: "about" */ '../views/IndexResumeView.vue'),
      },
    ]
  },
  {
    path: '/test',
    name: 'test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue')
  },
  {
    path: '/tools',
    name: 'toolsIndex',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ToolsIndexView.vue'),
    children:[
      {
        path: '/changeCase',
        name:'changeCase',
        component: () => import(/* webpackChunkName: "about" */ '../views/tools/ToolsChangeCaseView.vue'),
      },
      {
        path: '/countStatistics',
        name:'countStatistics',
        component: () => import(/* webpackChunkName: "about" */ '../views/tools/ToolsCountStatistics.vue'),
      },
      {
        path: '/timestamp',
        name:'timestamp',
        component: () => import(/* webpackChunkName: "about" */ '../views/devTools/TimestampView.vue'),
      },
      {
        path: '/getString',
        name:'getString',
        component: () => import(/* webpackChunkName: "about" */ '../views/devTools/GetStringView.vue'),
      },
      {
        path: '/encryption',
        name:'encryption',
        component: () => import(/* webpackChunkName: "about" */ '../views/devTools/EncryptionView.vue'),
      },
      {
        path: '/SQLCreateView',
        name:'SQLCreateView',
        component: () => import(/* webpackChunkName: "about" */ '../views/devTools/SQLCreateView.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
